import $ from 'jquery'

class NavigationMainChildOld extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.resolveElements()
    this.bindFunctions()
    this.initFixed()
    this.initScrollbar()
  }

  resolveElements () {

  }

  bindFunctions () {

  }

  connectedCallback () {}

  initFixed () {
    $(window).on('scroll', function () {
      if (window.pageYOffset > 600) {
        $('.mainHeader').addClass('is--sticky')
      } else {
        $('.mainHeader').removeClass('is--sticky')
      }
    })
  }

  initScrollbar () {
    if ($('html').hasClass('single-post')) {
      $(window).on('scroll', function () {
        if (window.pageYOffset > 600) {
          var winScroll = document.body.scrollTop || document.documentElement.scrollTop
          var currentHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight
          var scrolled = (winScroll / currentHeight) * 100
          $("#scroll-bar").css('width', scrolled + "%")
        } else {
          $("#scroll-bar").css('width', "0%")
        }
      })
    }
  }
}

window.customElements.define('fusion-navigation-main-child-old', NavigationMainChildOld, { extends: 'div' })
