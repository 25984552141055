'use strict'

import $ from 'jquery'

class BlockCookieNoticeChild extends window.HTMLDivElement {

  constructor (self) {
    self = super(self)
    self.$ = $(self)
    self.setOptions()
    self.resolveElements()
    return self
  }

  setOptions () {
    this.cookieName = 'cookieNoticeSeen'

  }

  resolveElements () {
    this.$denyButton = $('[data-deny]', this)
    this.$customButton = $('[data-custom]', this)
    this.$agreeButton = $('[data-agree]', this)
    this.$cookieBanner = $('#cookie-banner', this)
    this.$cookiePanel = $('#cookie-panel', this)
    this.$cookieSwitchOff = $('[data-status="0"]', this)
    this.$switchs = $('.switchStatus', this)
    this.$dataValue = $(this.$switchs.data('status'), this)
    this.$close = $('[aria-label="close"]', this)
    this.$alwaysOpen = $('.openCookieDashboard')

  }

  connectedCallback () {

    if (!(this.readCookie('cookiesAgreement'))) this.showCookieBanner()

    this.$alwaysOpen.on('click', this.showCookiePanel.bind(this))
    this.$customButton.on('click', this.showCookiePanel.bind(this))
    this.$denyButton.on('click', this.unCheckAllCookies.bind(this))
    this.$close.on('click', this.hideCookieNotice.bind(this))
    this.$agreeButton.on('click', this.AcceptCookies.bind(this))

    this.$switchs.on('click', this.handleCookie.bind(this))
  }


  /***************************************************************/
  /*                          Functions                          */
  /***************************************************************/


  createCookie (name, value, days) {

    if (days) {
      var date = new Date()
      date.setTime(date.getTime()+(days*24*60*60*1000));
      var expires = "; expires="+date.toUTCString();
    } else {
      var expires = "";
    }
    console.log(name+"="+value+expires+"; path=/")
    document.cookie = name+"="+value+expires+"; path=/";
  }

  readCookie (name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  eraseCookie (name) {
    this.createCookie(name,"",-1);
  }

  handleCookie (event) {
    if ($(event.target).prop('checked'))
    {
      this.createCookie('cookien' + $(event.target).attr('id'), '1', '30')
    } else {
      this.createCookie('cookien' + $(event.target).attr('id'), '0', '30')
    }
  }

  showCookiePanel () {
    this.$cookieBanner.addClass('hidden')
    this.$cookiePanel.removeClass('hidden')
  }

  showCookieBanner () {
    this.$cookieBanner.removeClass('hidden')
  }

  getSwitchValue (e) {
    const value = e.currentTarget.value;
    return value
  }

  unCheckAllCookies () {
    this.$cookieSwitchOff.removeAttr('checked')
    var self = this
    this.$switchs.each(function (index) {
      self.createCookie('cookien' + $(this).attr('id'), '0', '30')
    })
    this.createCookie('cookiesAgreement', '1', '30')
    this.$cookieBanner.addClass('hidden')
    this.$cookiePanel.addClass('hidden')
  }


  AcceptCookies (e) {
    e.preventDefault()
    this.createCookie('cookiesAgreement', '1', '30')
    this.$cookiePanel.addClass('hidden')
    this.$cookieBanner.addClass('hidden')
  }

  hideCookieNotice (e) {
    e.preventDefault()
    this.$cookiePanel.addClass('hidden')
    this.createCookie('cookiesAgreement', '1', '30')
  }

}

window.customElements.define('fusion-block-cookie-notice-child', BlockCookieNoticeChild, { extends: 'div' })
