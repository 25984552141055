import $ from 'jquery'
import 'swiper/swiper-bundle.min.css'
import Swiper from 'swiper/bundle';

class BlockCarousel extends window.HTMLDivElement {

    constructor(...args) {
        const self = super(...args)
        self.init()
        return self
    }

    init() {
        this.$ = $(this)
        this.resolveElements()
    }


    resolveElements() {
        
    }

    connectedCallback() {
        $(document).ready( () => {
            this.initSlider()
        })
    }

    initSlider() {

        var numberOfSlides = $('.mySwiperCarousel', this).data('nbitems')
        
        var swiper = new Swiper(".mySwiperCarousel", {
            loop: true,
            allowSlidePrev: numberOfSlides === 1 ? false:true,
            allowSlideNext: numberOfSlides === 1 ? false:true,
            pagination: {
                el: ".swiper-pagination",
            },
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });

        if( numberOfSlides === 1 ) { $('.swiper-button-next,.swiper-button-prev', this).remove() }
    }

}

window.customElements.define('fusion-slider-carousel', BlockCarousel, { extends: 'div' })

