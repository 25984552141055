import $ from 'jquery'
import 'swiper/swiper-bundle.min.css'
import Swiper from 'swiper/bundle';

class SliderHeaderChild extends window.HTMLDivElement {

    constructor(...args) {
        const self = super(...args)
        self.init()
        return self
    }

    init() {
        this.$ = $(this)
        this.resolveElements()
    }


    resolveElements() {
        
    }

    connectedCallback() {
        $(document).ready( () => {
            this.initSlider()
        })
    }

    initSlider() {

        const swiper = new Swiper('.swiper-container', {
            loop: true,
            
            pagination: {
              el: '.slider-pagination',
            },

            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              },
        
        });
    }

}

window.customElements.define('fusion-slider-child-header', SliderHeaderChild, { extends: 'div' })

