import $ from 'jquery'

class ChildComponents9 extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.resolveElements()
    this.bindFunctions()
    this.bindEvents()
  }

  resolveElements () {
    this.$posterImage = $('.figure-image', this)
    this.$videoPlayer = $('.video-player', this)
    this.$videoElem = $('.video', this)
    this.$videoHandler = $('.video-Handler', this)
    this.$iframe = $('iframe', this)
    this.$playButton = $('.video-playButton', this)
  }

  bindFunctions () {
    this.loadVideo = this.loadVideo.bind(this)
    this.videoIsLoaded = this.videoIsLoaded.bind(this)
  }

  bindEvents () {
    this.$videoElem.one('click', this.loadVideo)
  }



  loadVideo () {
    this.$iframe.one('load', this.videoIsLoaded.bind(this))
    this.$iframe.attr('src', this.$iframe.attr('src') + '&autoplay=1')
    this.$videoPlayer.addClass('video-player--isLoading')
  }

  videoIsLoaded () {
    this.$videoPlayer.removeClass('video-player--isLoading')
    this.$videoPlayer.addClass('video-player--isLoaded')
    this.$videoHandler.remove()
  }
}

window.customElements.define('fusion-child-nine', ChildComponents9, { extends: 'div' })
