import './main.scss'
import $ from 'jquery'
import installCE from 'document-register-element/pony'

window.jQuery = $

window.lazySizesConfig = window.lazySizesConfig || {}
window.lazySizesConfig.preloadAfterLoad = true
require('lazysizes')

$(document).ready(function () {

  if (process.env.NODE_ENV === 'development') {
    console.log(process.env.NODE_ENV + ' mode')
  } else {
    console.clear()
    console.log('Made with ♥ by ISOMORPH')
  }

    /**
    * Smooth scrolling to page anchor on click
    **/
  $("a[href*='#']:not([href='#'])").click(function() {
    if (
      location.hostname == this.hostname
      && this.pathname.replace(/^\//,"") == location.pathname.replace(/^\//,"")
    ) {
      var anchor = $(this.hash);
      anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) +"]");
      if ( anchor.length ) {
        $("html, body").animate( { scrollTop: (anchor.offset().top - 150) }, 1500);
      }
    }
  })

})

installCE(window, {
  type: 'force',
  noBuiltIn: true
})

function importAll (r) {
  r.keys().forEach(r)
}

importAll(require.context('../Components/', true, /script\.js$/))